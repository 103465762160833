//
// Full Calendar
//

.fc {
    .fc-button {
        background-color: #14c49b;
        color: rgb(255, 255, 255);
        border-color:  #14c49b;
    }

    .fc-listViewButton-button {
        background-color: #14c49b;
        color: rgb(255, 255, 255);
        border-color:  #14c49b;
        padding: 0.65rem 1rem;
        font-size: 1rem;
        line-height: 1.5;
    }
}


