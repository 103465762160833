.clickable {
  cursor: pointer;
}

.opened-container {
  display: flex-column;
  z-index: 999;
  position: fixed;
  bottom: 0%;
  right: 2%;
  background-color: white;
  padding: 1rem;
  /* width: 15%; */
  width: 18%;
  /* height: 50%; */
  height: 65%;
  border-radius: 2%;
  box-shadow:
    0 1px 2px rgba(0, 0, 0, 0.07),
    0 2px 4px rgba(0, 0, 0, 0.07),
    0 4px 8px rgba(0, 0, 0, 0.07),
    0 8px 16px rgba(0, 0, 0, 0.07),
    0 16px 32px rgba(0, 0, 0, 0.07),
    0 32px 64px rgba(0, 0, 0, 0.07);
}

.closed-container {
  z-index: 999;
  position: fixed;
  bottom: 0%;
  right: 2%;
  background-color: white;
  cursor: pointer;
  padding: 1rem;
  /* width: 15%; */
  width: 18%;
  height: 8%;
  border-radius: 10px 10px 0 0;
  font-weight: bold;
  box-shadow:
    0 1px 2px rgba(0, 0, 0, 0.07),
    0 2px 4px rgba(0, 0, 0, 0.07),
    0 4px 8px rgba(0, 0, 0, 0.07),
    0 8px 16px rgba(0, 0, 0, 0.07),
    0 16px 32px rgba(0, 0, 0, 0.07),
    0 32px 64px rgba(0, 0, 0, 0.07);
}

.finished {
  text-decoration: line-through;
}

.text-normal {
  color: inherit;
}
