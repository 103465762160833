.analytics {

    &-card {
        padding: 0;

        // &:not(:first-of-type) {
        //     padding-top: 0 !important;
        // }
        
    }

    &-side-percent {
        bottom: 20%;
    }
}


// Graphs on dashboard and analytics
.sales-by-fulfillment, .bar-graph-of-sales, .total-sales-for-today, .average-customer-spent {

    & .barContainer {
        // background-color: magenta;
        min-width: 8rem;

    }

    & .barHeader {
        color: #212121;
        background-color: #F3F6F9;
        font-weight: 300;
        font-size: 1rem;
        padding: .5rem .8rem;
    }

    & .barContent {
        padding: .5rem 1rem;
        font-weight: 300;
        font-size: 1rem;
    }

    & .barSeriesContent {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    & .barData {
        padding-left: 2rem;
        margin-left: auto;

        & .deduction {
            color: $danger;
        }
    }

    

    & .barBadge {
        width: .8rem;
        height: .8rem;
        padding: .1rem;
        border-radius: 50%;
        background-color: black;
        margin-right: .5rem
    }

    & .bold {
        font-weight: 600;
    }

    & .textBig {
        font-size: 1.5rem;
    }
}

// .modal-dialog{
//     overflow-y: initial !important
// }
// .modal-body{
//     height: 80vh;
//     overflow-y: auto;
// }
// .modal-footer {
//     border-radius: 0;
//     bottom:0px;
//     position:absolute;
//     width:100%;
//     background-color: white;
// }

.second-modal .modal-content {
    box-shadow: 0 0 30px #333;
}

.item-photo-wrapper {
    @include media-breakpoint-down(xs) {
        display: none;
    }
}

.store-name-card {
    padding: 2rem 0 2rem 0;
    border-radius: 5px;
    color: $primary;
    background-color: white;
    font-weight: 600;
    font-size: 1.25rem;
    margin-right: 1rem;
    margin-bottom: 1rem;
    text-align: center;
    cursor: pointer;
}

.store-name-card:hover {
    background-color: #F3F6F9;
    color: $primary;
}

.accordion {
    border: none !important;
}

.accordion__button, .accordion__button:hover {
    background-color: transparent;
}

.accordion__button:before {
    height: 15px;
    width: 15px;
    border-bottom: 3px solid currentColor;
    border-right: 3px solid currentColor;
}

// .onboarding {

//     &-email {
//         // background:red;

//         &:hover &-copy {
//             display: block;
//         }
//     }
// }

.bw-reservations {
    .bw-section {
        margin-bottom: 0 !important;
        .bw-section-content {
            padding: 0 !important;
        }
    }
}

.reservation-overview-card {
    border: 1.5px solid #464E5F;
    border-radius: 4px !important;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    cursor: pointer;

    .overview-col {
        border-right: 1.5px solid #000000;
    }
}