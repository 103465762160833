.header-mobile {
    &.bw {
        border-bottom: none;
    }
}

.flex-root.bw {
    background: #ffffff;

    .header .container-fluid,
    .header .container-sm,
    .header .container-md,
    .header .container-lg,
    .header .container-xl,
    .header .container-xxl {
        border-bottom: none;
        box-shadow: 0px 1px 9px -3px rgba(0, 0, 0, 0.1);
    }
}

.gm-style .gm-style-iw-c {
     background-color: #525252 !important;
 }

 .gm-style .gm-style-iw-d::-webkit-scrollbar-track, 
 .gm-style .gm-style-iw-d::-webkit-scrollbar-track-piece,
 .gm-style .gm-style-iw-c,
 .gm-style .gm-style-iw-t::after { 
   background: #525252;
 }

 button.gm-ui-hover-effect {
    visibility: hidden;
 }
 
 .gm-style .gm-style-iw-tc::after {   background: #525252; }
.bw-section {
    margin-bottom: 1rem;

    .bw-section-header {
        color: $primary;
        font-size: 1.5rem;
        font-weight: 500;
        padding-bottom: 0;
        border-bottom: 2px solid $primary;
        margin: 1rem 0;
    }

    .bw-section-content {
        padding: 1rem 0.5rem;
    }
}

.discount-style {
    position: absolute;
    top: 0;
    right: 0;
    color: red;
    background-color: rgba(250, 250, 250, 0.5);
    font-size: 0.8em;
    font-weight: bolder;

    @include media-breakpoint-down(xs) {
        font-size: 1.7em;
    }

}

.bw-iac-wrapper {
    .bw-iac-item {

        .bw-iac-item--category-name {
            color: $primary;
            font-size: 1.5rem;
            font-weight: 500;
            padding-bottom: 2px;
            border-bottom: 2px solid $primary;
            margin: 1rem 0;

            @include media-breakpoint-down(md) {
                margin: .5rem 0;
            }

            @include media-breakpoint-down(md) {
                font-size: 1.3rem;
            }

            span {
                vertical-align: middle;
            }

            .label {
                float: right;
            }
        }

        .bw-iac-item--items-wrapper {
            @include media-breakpoint-down(xs) {
                flex-direction: row;
                display: flex;
                flex-wrap: wrap;
                width: 100%;

            }

            .bw-iac-item--item-wrapper {
                @include media-breakpoint-down(xs) {
                    display: flex;
                    flex-direction: column;
                    width: 50%;
                    box-shadow: 0px 0px 5px -3px rgba(0, 0, 0, 0.5);
                }

                .item {
                    @include media-breakpoint-down(xs) {
                        text-align: left;
                    }

                    margin: 1rem 0;
                    padding: 0.7rem 0.5rem;

                    &:hover {
                        border-bottom: none;
                        box-shadow: 0px 1px 9px -3px rgba(0, 0, 0, 0.1);
                    }

                    .d-flex {
                        @include media-breakpoint-down(xs) {
                            flex-direction: column;
                            display: flex;
                        }
                    }

                    .menu-image-container {
                        @include media-breakpoint-down(xs) {
                            flex-direction: row;
                            margin-right: 0 !important;
                        }
                    }

                    .menu-image {
                        @include media-breakpoint-down(xs) {
                            // width: 40vw;
                            // height: 120px;
                            width: 35vw;
                            height: 35vw;
                            border-radius: 5%;
                            object-fit: cover;
                            object-position: center;
                        }
                    }

                    .item-photo-wrapper {
                        @include media-breakpoint-down(xs) {
                            // display: none;
                            flex-direction: row;

                        }
                    }

                    .item-text-wrapper {
                        @include media-breakpoint-down(xs) {
                            margin-left: 5.5vw;
                        }

                        .item-name {
                            font-weight: 600;

                            @include media-breakpoint-down(xs) {
                                font-size: 1.2rem;
                                text-align: left;
                            }

                            .un-tag {
                                @include media-breakpoint-down(xs) {
                                    margin-left: 0 !important;
                                    margin-right: 5vw !important;
                                }
                            }
                        }

                        .item-price {
                            font-weight: 500;

                            @include media-breakpoint-down(xs) {
                                text-align: left;
                            }
                        }

                        .item-limit {
                            @include media-breakpoint-down(xs) {
                                // text-align:"center" !important;
                                text-align: left;
                            }
                        }

                        // .item-desc{
                        //     @include media-breakpoint-down(xs) {
                        //         // text-align:"center" !important;
                        //         display: none
                        //     }
                        // }
                        .item-disc-desc {
                            @include media-breakpoint-down(xs) {
                                // text-align:"center" !important;
                                display: none;
                                text-align: left;
                            }
                        }

                        width: 60%;

                        @include media-breakpoint-down(md) {
                            width: 40%;
                        }

                        @include media-breakpoint-down(sm) {
                            width: 30%;
                        }

                        @include media-breakpoint-down(xs) {
                            width: 100%;
                        }
                    }

                    .item-actions {
                        @include media-breakpoint-down(md) {
                            width: 35%;
                        }

                        @include media-breakpoint-down(xs) {
                            width: 40%;
                        }
                    }

                }

                .item-tags {
                    @include media-breakpoint-down(xs) {
                        margin-top: auto;
                        margin-left: 1vw;

                        div:nth-child(n+3) {
                            display: none;
                        }
                    }
                }

                .white-out {
                    position: relative;
                    display: inline-block;
                }

                .white-out:after {
                    position: absolute;
                    content: '';
                    display: block;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    background: rgba(255, 255, 255, .5);
                }
            }
        }
    }
}

.skel {
    @include media-breakpoint-down(xs) {
        width: 35vw !important;
        height: 35vw !important;
    }

}

.bw-cart {
    .bw-cart-items-added {
        .bw-section-header {
            color: $dark-75;
            font-size: 1.2rem;
            border-bottom: 1px solid $gray-400;
            margin-top: 0;

        }

        .bw-section-content {
            padding: 0;

            .bw-cart-items--total-amount {
                border-top: 2px solid $gray-400;
                padding-top: 0.5rem;
                font-size: 1.2rem;
                display: flex;
                justify-content: space-between;
            }
        }
    }
}

.bw-cart-items-wrapper {
    .item {
        margin: 0.75rem 0;
        cursor: auto;

        .item-text-wrapper {
            .item-name {
                font-weight: 600;
                font-size: 1.1rem;
            }

            .item-extras-name {
                font-size: 0.9rem;
            }
        }

        .item-price {
            font-weight: 600;
            font-size: 1rem;
            margin: 0.3rem 0;
        }
    }

    .separator:last-child {
        display: none;
    }
}

.bw-hero {
    color: $light;

    &.bw-hero--no-bg {
        color: #464e5f;
    }

    .bw-hero--text {
        font-size: 2.5rem;
        font-weight: 700;
    }

    .bw-hero--actions {
        margin-top: 3rem;

        .bw-hero--actions-subtitle {
            font-size: 1.2rem;
            font-weight: 600;
            margin: 1rem 0;
        }

        .btn {
            margin-right: 5px;
        }
    }
}

.bw-section.checkout {
    .bw-section-content {
        .checkout-banner {
            font-size: 1.3rem;
        }
    }
}

.menu-link.menu-toggle.menu-toggle--cart {
    border: 1px solid $primary;

    .menu-text {
        color: $primary;
    }
}

.custom-modal-dialog {
    @include media-breakpoint-down(xs) {
        position: relative;
        width: auto;
        margin: 0 !important;
        pointer-events: none;
    }
}

.gs-scroller {
    max-height: calc(100vh - 200px);
}

.addtocart {

    // @include media-breakpoint-down(xs) {
    //     min-height: 90vh !important;
    // }    
    &-wrapper {
        @include media-breakpoint-down(xs) {
            min-height: 100vh !important;
            width: 100vw !important;
        }
    }

    &-img-wrapper {
        width: 100% !important;
        padding: 0;
        margin: 0;
        overflow: hidden;

        @include media-breakpoint-down(xs) {
            width: 100vw !important;
        }
    }

    &-close {
        position: absolute !important;
        left: 1rem !important;
        top: 2rem !important;
        z-index: 10;
    }

    &-img {
        width: 100% !important;
        height: 25rem !important;
    }


    // &-header {}

    &-body {
        padding: 0;
    }


    &-footer {
        padding: .5rem 1.23rem;
        margin-top: 1.5rem;
        box-shadow: 0 -2px 4px 1px rgba(0, 0, 0, 0.2);
        align-items: center !important;

        @include media-breakpoint-down(xs) {
            position: fixed;
            bottom: 0vh;
            align-items: baseline !important;
        }



        &-controller {
            flex-shrink: 0;
        }

        &-btn {
            flex: 1 1 300px;
            padding: .75rem 1.5rem;
            font-size: 1.4rem;
        }

    }
}

.symbol-100.symbol-2by3 .symbol-label {
    @include media-breakpoint-down(md) {
        height: 50px !important;
        width: 75px !important;
    }
}

.symbol.symbol-65.symbol-2by3 .symbol-label {
    @include media-breakpoint-down(md) {
        height: 50px !important;
        width: 75px !important;
    }
}

.bw-cart-icon {
    position: relative;

    .label {
        position: absolute;
        right: 0px;
    }
}

.bw-logo {
    min-height: 50px;
    max-height: 70px;
    max-width: 300px;
}

.homepage-logo {
    min-height: 50px;
    max-height: 150px;
    max-width: 300px;
}

.selection {

    &-item {

        &-title {
            flex: 1 1 auto;
        }

        &-tag {
            flex: 0 0 auto;
        }
    }
}

.track-page-width {
    width: 420px;

    @include media-breakpoint-down(sm) {
        width: 350px;
    }
}

.highlight-item {
    animation-name: color_change;
    animation-duration: 0.5s;
    animation-iteration-count: initial;
}

@keyframes color_change {
    from {
        background-color: $primary-light;
    }

    to {
        background-color: none;
    }
}

.prio_fee_font_size {
    font-size: 10px;
}