// Custom helpers

.d-none {
    display: none!important;
}

.va-middle {
    vertical-align: middle !important;
}

.va-text-bottom {
    vertical-align: text-bottom;
}

.va-baseline {
    vertical-align: baseline;
}

.va-bottom {
    vertical-align: bottom;
}

.font-pickup-md {
    font-size: 1.5rem;

    @include media-breakpoint-down(sm) {
        font-size: 1rem;
    }
}

.mv-1rem { 
    margin: 1rem 0;
}

.br-0 { border-radius: 0; }

.box-shadow-1 {
    border-bottom: none;
    box-shadow: 0px 1px 9px -3px rgba(0, 0, 0, 0.1);
}

.inactive-link { pointer-events: none; }

.b-none { border: none; }

.font-pickup-lg { font-size: 2rem; }

.font-pickup-sm { font-size: 1rem; }

.font-pickup-sm-2 { font-size: 1.2rem; }

.font-card-label {
    font-weight: 600;
    font-size: 1.275rem;
    color: #212121;
}

.display-mobile-only {
    display: none;

    @include media-breakpoint-down(md) {
        display: block;
    }

    &.d-flex {
        display: none!important;

        @include media-breakpoint-down(md) {
            display: flex !important;
        }
    }
}

.display-large-and-up {
    display: block;

    @include media-breakpoint-down(md) {
        display: none;
    }
}

.display-medium-and-down {
    display: none;

    @include media-breakpoint-down(md) {
        display: block;
    }
}


.hide-on-mobile {
    @include media-breakpoint-down(sm) {
        display: none;
    }
}

.col-padding {
    padding: 1.5rem 2.25rem;

    @include media-breakpoint-down(md) {
        padding: 1rem;
    }
}

.primary-border-bottom {
    border-bottom: 5px solid $primary;
}

.secondary-border-bottom {
    border-bottom: 2px solid $info;
}

.font-hover-primary {
    &:hover {
        color: $primary;
    }
}

.justify-content-space-between {
    justify-content: space-between;
}

.justify-content-space-evenly {
    justify-content: space-evenly;
}

.justify-content-space-around {
    justify-content: space-around;
}

.justify-content-end {
    justify-content: end;
}

.btn-purple {
    color: #FFFFFF;
    border-color: $pickup-purple;
    background-color: $pickup-purple;

    &:hover {
        color: #FFFFFF;
    }
}

.text-purple {
    color: $pickup-purple;
}

.label-purple {
    color: #ffffff;
    background-color: $pickup-purple;
}

.text-electric-blue {
    color: #2049f9;
}

.text-label {
    color: #80808F;
}

.text-value {
    color: #212121;
}

.order-text-hover-primary {
    &:hover {
        color: $primary;
    }
}

.font-card-title {
    font-size: 1.5rem;
}

.font-card-body {
    font-size: 1rem;
}

.font-order-section-header,
.font-reservation-section-header {
    font-size: 1.5rem;

    @include media-breakpoint-down(md) {
        font-size: 1.2rem;
    }
}

.order-section {
    margin: 1rem 0;

    .order-section--header {
        margin: 0.5rem 0;

        font-size: 1.5rem;

        @include media-breakpoint-down(md) {
            font-size: 1.2rem;
        }
    }
    
    .order-section--body {
        font-size: 1.1rem;
        font-weight: 600;
        text-transform: uppercase;

        @include media-breakpoint-down(md) {
            font-size: 1rem;
        }

        div {
            padding: 0.2rem 0;
        }
    }
}

.modal-body-max-height{
    max-height: calc(100vh - 200px);
    overflow-y: auto;
}

.underline {
    border-bottom: 1px solid #212121;
}

.width-100 {
    width: 100%;
}

.mw-72p.col-lg-9 {
    max-width: 72%;

    @include media-breakpoint-down(md) {
        max-width: 100%;
    }
}

.circle-div {
    display: flex;
    width: 10px;
    height: 10px;
    border-radius: 50%;
}