//
// To make future updates easier consider overriding the global variables from _variables.bootstrap.scss and _variables.custom.scss for current demo in this file.
// Note that this file is included first and variables defined in _variables.bootstrap.scss and _variables.custom.scss
// are not accessible in this file but you can override any global variable as shown below:
//

// Theme colors
// Override primary color variants
$primary:       									#14C49B !default;
$primary-hover:    									lighten($primary, 10%) !default;
$primary-light:    									lighten($primary, 10%) !default;
$primary-inverse:  									#FFFFFF !default; 
$pickup-purple:                                     #8750ef;
$pickup-green:       						        #14C49B;