.store-logo-div-image-container {
  padding-top: 3rem;
  padding-bottom: 3rem;
  text-align: center;
}

.store-logo-div-image {
  max-width: 275px;
  max-height: 100px;
}

.text-gray {
  color: #464e5f;
}
