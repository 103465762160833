.card {
    &.card-border-top-primary {
        border-top: 5px solid $primary;
        border-radius: 0;

        &.card-selected {
            border: 1px solid $primary;
            border-top-width: 5px;
            border-radius: 4px;
        }
    }
}

.pricing-item {
    border: 1px solid $gray-200;
    border-radius: 4px;
    margin-bottom: 30px;
    padding: 1rem;
    cursor: pointer;

    .pricing-item-label,
        .pricing-item-price {
        font-weight: 600;
    }

    .pricing-item-label {
        font-size: 1.35rem;
        margin-bottom: 0.5rem;
    }

    .pricing-item-desc {
        margin: 0.5rem 0;
        font-size: 1.1rem;
    }

    .pricing-item-features {
        .pricing-item-feature {
            font-size: 1.1rem;
            margin-bottom: 0.5rem;
        }
    }

    &:hover {
        border-color: $primary;
        transition: border 200ms ease-out;

        .pricing-item-label,
            .pricing-item-price {
            color: $primary;
        }
    }

    &.pricing-item-selected {
        border-right: 5px solid $primary;
        border-color: $primary;
        border-radius: 0;
        transition: border 200ms ease-out;

        .pricing-item-label,
            .pricing-item-price {
            color: $primary;
        }
    }
}

.brand-logo {
    img {
        width: 170px;
    }
}

.header-logo {
    a img {
        width: 170px;
    }
}

.header-mobile {
    padding-left: 5px;

    a {
        img {
            width: 150px;
        }
    }
}

.fv-plugins-message-container {
    .fv-helper-text {
        font-size: 1rem;
        font-weight: 400;
    }
}

.steps {
    display: grid;
    grid-column-gap: 1rem;

    @include media-breakpoint-up(lg) {
        grid-template-columns: 1fr 1fr 1fr;
        margin-top: 2rem;
    }

    @include media-breakpoint-between(xs, lg) {
        grid-template-columns: 1fr;
        margin-top: 1rem;
    }

    .step {
        margin: 1rem 0;
        padding: 1rem 1.5rem;
        border: 1px solid $primary;
        border-radius: 5px;

        // &.step-done {
        //     border-bottom-width: 10px;
        // }

        &.step-current {
            border-width: 3px;
            border-bottom-width: 5px;
        }

        .step-subtitle {
            text-transform: uppercase;
        }

        .step-name {
            font-weight: 600;

            @include media-breakpoint-up(lg) {
                font-size: 1.5rem;
            }

            @include media-breakpoint-between(xs, lg) {
                font-size: 1.2rem;
            }
        }

        &:hover {
            .step-name {
                color: $primary;
            }
        }
    }
}

.form-control-datepicker {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 0;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    &:focus {
        outline: none;
    }
}

.card-body-title {
    font-size: 2rem;
    font-weight: 500;
}

.required-asterisk {
    font-size: inherit;
    color: $danger;
}

.display-brand-logo {
    width: 200px;
}

.display-brand-logo-external {
    height: 60px;
    max-width: 180px;
}

.kt-list-item {
    .kt-list-item-text-container {
        .kt-list-item-text-label {
            color: $dark;
        }
    }

    &:hover {
        .kt-list-item-text-container {
            .kt-list-item-text-label {
                color: $primary;
            }
        }
    }
}

.pickup-img-wrapper {
    padding-bottom: 3rem;
}

.card-img-banner {
    .card-img-banner-body {
        .card-img-banner-body-img-wrapper {
            padding-bottom: 3rem;
            border-bottom: 1px solid $gray-200;
        }

        .card-img-banner-body-text-wrapper {
            margin: 1.5rem 0;

            .card-img-banner-body-text-title {
                font-size: 1.2rem;
                font-weight: 500;
            }
        }

        .card-img-banner-body-actions-wrapper {
            text-align: center;
        }
    }
}

.toc {
    margin: 1rem 0;

    .toc-title {
        font-size: 1.5rem;
        font-weight: 500;
    }

    .toc-subtitle {
        font-size: 1.2rem;
    }
}

.header {
    .container-fluid {
        border-bottom: 1px solid $primary;
    }

                                                                                                                                .header-fixed {
        .container-fluid {
            padding-left: 10px;
        }
    }
}

.rbt-highlight-text {
    margin-left: 2px;
}

.item {
    cursor: pointer;

    .item-text-wrapper {
        .item-name {
            font-weight: 500;
            font-size: 1.2rem;

            @include media-breakpoint-down(md) {
                font-size: 1rem;
            }
        }

        .item-desc {
            @include media-breakpoint-down(md) {
                font-size: 0.9rem;
            }
        }
    }
}

.group-name {
    font-size: 1.4rem;
    cursor: grab;

    span {
        padding: 5px;
        cursor: pointer;
&:hover {
            background-color: rgba(0, 0, 0, 0.05);
        }
    }
}

td.group-name {
    padding-left: 0 !important;
}

td.icon {
    vertical-align: middle;
}

.per-category-group-table {
    margin-bottom: 5rem;
    overflow: hidden;
}

.item-tr {
    td {
        &:hover {
            background-color: rgba(0, 0, 0, 0.05);
        }

        &.no-items {
            &:hover {
                background-color: unset;
            }
        }
    }
}

.card-body {
    &.card-body-min-350 {
        min-height: 350px;
    }
}

.paragraph {
    font-size: 1.1rem;
    font-weight: 500;
}

.order-details {
    .item:first-of-type {
        margin-top: 0;
    }
}

.standard-card-body {
    height: 500px;
}

.label-pink {
    background-color: #ea178b;
    color: #FFFFFF;
}

.label-blue {
    background-color: #3699FF;
    color: #FFFFFF;
}

.width-250 {
    width: 250px;
}

.order-card-details {
    div {
        margin-right: 0.5rem;
        display: inline-block;

        @include media-breakpoint-down(sm) {
            display: block;
        }
    }
}

.btn-primary {
    .svg-icon-custom-primary {
        fill: $white !important;

        &:hover {
            fill: $white !important;
        }
    }
}

.track-status-label {
    margin: 0.5rem 0;
    font-size: 0.75rem;
    font-weight: 500;
    text-transform: uppercase;
}

.wrapper.no-header {
    padding-top: 0px;
}

.css-qwibvh {
    position: relative;
    height: 100%;
    background-color: rgb(233, 233, 233);
    min-height: 100vh;

    @media (min-width: 768.1px) {
        min-height: auto;
    }
}

.css-1ucwpv3 {
    display: flex;
    flex-direction: column;
    -webkit-box-pack: justify;
    justify-content: space-between;
    background-color: white;
    padding: 48px 16px 0px;
    min-height: 100vh;

    @media (min-width: 768.1px) {
        padding: 0px;
        overflow-y: hidden;
        width: 45%;
    }

    @media (min-width: 960.1px) {
        width: 40%;
    }

}

.css-luxby8 {
    z-index: 10;
    flex-shrink: 0;
    background-color: white;
    padding: 40px 24px;
    position: relative;
    min-height: 80vh;
    -webkit-box-pack: center;
    justify-content: center;
    text-align: center;
    padding-top: 30px;

    @media (min-width: 768.1px) {
        margin: 0px;
        overflow-y: auto;
        padding: 48px;
        justify-content: left;
        text-align: left;
        min-height: 100vh;
        padding-top: 30px;
    }

    @media (min-width: 1012.1px) {
        padding: 56px;
        padding-top: 30px;
    }
}

.css-1e4rxnh {
    position: fixed;
    inset: 0px;
    min-height: 360px;
    height: 40vh;

    @media (min-width: 768.1px) {
        left: 45%;
        bottom: 0px;
        height: 100vh;
    }

    @media (min-width: 960.1px) {
        left: 40%;
    }
}

.svg-icon35x {
    svg {
        width: 3.5rem !important;
        height: 3.5rem !important;
    }
}

.btn-primary {
    .svg-icon-custom-primary {
        fill: $white !important;

        &:hover {
            fill: $white !important;
        }
    }
}

.btn-outline-primary {
    .svg-icon-custom-primary {
        fill: $primary !important;
    }

                                                                                                                                &:hover {
        .svg-icon-custom-primary {
            fill: $white !important;
        }
    }
}

@mixin svg-icon-fill ($fillcolor) {
    fill: $fillcolor;
}

.location-search-input,
.location-search-input:focus,
.location-search-input:active {
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 0 1px rgba(0, 0, 0, 0.08);
        border: honeydew;
        display: block;
        width: 100%;
        padding: 16px;
        font-size: 16px;
        border-radius: 2px;
        outline: none;
}

.autocomplete-dropdown-container {
    border-bottom: honeydew;
        border-left: honeydew;
        border-right: honeydew;
        border-top: 1px solid #e6e6e6;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        position: absolute;
        z-index: 1000;
        width: 92%;
        border-radius: 0 0 2px 2px;
}

$backgroundcolor: #EEF0F8;

.general-bg-color {
    background-color: $backgroundcolor;
}

.gen-white-out {
    position: relative;
    display: inline-block;

    &:after {
        position: absolute;
        content: "";
        display: block;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: hsla(0, 0%, 100%, .5);
    }
}

.modal-scrollable {
    max-height: calc(100vh - 500px);
    overflow-y: auto;
    overflow-x: hidden;
}

.modal-content {
    width: 97%;
    margin-left: 5px;
}

.modal-content-xl {
    .modal-dialog {
        max-width: 1140px;
    }
    margin-left: 5px;
}

/* custom scrollbar */
.modal-scrollable::-webkit-scrollbar {
    width: 10px;
}

.modal-scrollable::-webkit-scrollbar-track {
    background-color: transparent;
}

.modal-scrollable::-webkit-scrollbar-thumb {
    background-color: #d6dee1;
    border-radius: 20px;
    border: 6px solid transparent;
    background-clip: content-box;
}

.modal-scrollable::-webkit-scrollbar-thumb:hover {
    background-color: #a8bbbf;
}

.break-lines {
    white-space: pre-wrap;
}

.menu-image {
    width: 90px;
    height: 60px;
    border-radius: 5%;
    object-fit: cover;
    object-position: center;
}

.item-modal-image {
    width: 100%;
    border-radius: 0.42rem;
    max-height: 280px;
    object-fit: cover;
    object-position: center;
}

@media screen and (max-width: 600px) {
    .item-modal-image {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border-bottom-left-radius: 0.42rem;
        border-bottom-right-radius: 0.42rem;
    }
}

.item-modal-image-container {
    position: relative;
    width: 100%;
    height: 280px;
}

.help-image1 {
    width: 50%;
    border-radius: 0.42rem;
    border: 1px solid #464E5F;
    display: block;
    object-fit: cover;
    object-position: center;
    margin: 10px auto;
}

.help-image2 {
    width: 25%;
    height: 75%;
    border-radius: 0.42rem;
    border: 1px solid #464E5F;
    margin: 5px;
}

// help page clear search button
input[type="search"]::-webkit-search-cancel-button {
    -webkit-appearance: none;
    appearance: none;
    height: 10px;
    width: 10px;
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pg0KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDE2LjAuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPg0KPCFET0NUWVBFIHN2ZyBQVUJMSUMgIi0vL1czQy8vRFREIFNWRyAxLjEvL0VOIiAiaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkIj4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCINCgkgd2lkdGg9IjEyMy4wNXB4IiBoZWlnaHQ9IjEyMy4wNXB4IiB2aWV3Qm94PSIwIDAgMTIzLjA1IDEyMy4wNSIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMTIzLjA1IDEyMy4wNTsiDQoJIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPGc+DQoJPHBhdGggZD0iTTEyMS4zMjUsMTAuOTI1bC04LjUtOC4zOTljLTIuMy0yLjMtNi4xLTIuMy04LjUsMGwtNDIuNCw0Mi4zOTlMMTguNzI2LDEuNzI2Yy0yLjMwMS0yLjMwMS02LjEwMS0yLjMwMS04LjUsMGwtOC41LDguNQ0KCQljLTIuMzAxLDIuMy0yLjMwMSw2LjEsMCw4LjVsNDMuMSw0My4xbC00Mi4zLDQyLjVjLTIuMywyLjMtMi4zLDYuMSwwLDguNWw4LjUsOC41YzIuMywyLjMsNi4xLDIuMyw4LjUsMGw0Mi4zOTktNDIuNGw0Mi40LDQyLjQNCgkJYzIuMywyLjMsNi4xLDIuMyw4LjUsMGw4LjUtOC41YzIuMy0yLjMsMi4zLTYuMSwwLTguNWwtNDIuNS00Mi40bDQyLjQtNDIuMzk5QzEyMy42MjUsMTcuMTI1LDEyMy42MjUsMTMuMzI1LDEyMS4zMjUsMTAuOTI1eiIvPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPC9zdmc+DQo=);
    background-size: 10px 10px;
    right: 10px;
}


.skel-image1 {
    display: block;
    justify-content: center;
    margin: 0 25%;
}

.skel-image2 {
    display: flex;
    margin: 0 15%;
}

.clickable {
    cursor: pointer;
}

.brand-website-bg-preview {
    max-width: 225px;
}

.marketplace-logo-preview {
    width: 150px;
    height: 150px;
    object-fit: cover;
}

.marketplace-banner-preview {
    width: 450px;
    height: 250px;
    object-fit: cover;
}

.no-zoom {
    font-size: 16px;
    transform-origin: top left;
    transform: scale(0.75);
    width: 240px;
    height: 32px;
    padding: 5.333333px;
    border-radius: 6.666667px;
    border-width: 1.333333px;
    margin-right: -60px;
    margin-bottom: -8px;
}


.bw-huge-padding {
    height: 80vh;
}

.reservations-count-div {
    position: sticky;
    overflow: hidden;

    &-nav {
        display: flex;
        align-items: center;

        &-item {
            &:link {
                color: $danger
            }
        }

        .nav {
            -ms-overflow-style: none;
                /* for Internet Explorer, Edge */
                scrollbar-width: none;
                /* for Firefox */
                overflow-x: scroll !important;
            &::-webkit-scrollbar {
                display: none;
                    /* for Chrome, Safari, and Opera */
            }
        }
    }

    &-item {
        &-desc {
            display: -webkit-box;
            overflow: hidden;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            white-space: normal;
        }
    }
}

.reservation-calendar {
    .active-calendar {
        background-color: #14C49B;
        border-radius: 30px;
        .active-calendar-day {
            background-color: #fff;
            border-radius: 30px;
        }
    }
    .svg-icon {
        border-radius: 5px;
        background-color: $primary;

        &:hover {
            background-color: #C9F7F5;
        }
    }
}

.category-menu {
    border-bottom: 2px solid $light;
    position: relative;
    overflow: hidden;
    // TODO: Verify with Marck
    // z-index: 10000;
    z-index: 999;

    &-btn {
        // TODO: Verify with Marck
        // z-index: 10001;
        z-index: 1000;
        position: absolute;
        top: 0;
        cursor: pointer;
        transition: all transform .3s;

        &:hover {
            transform: scale(1.1);
        }
    }

    &-right {
        right: 0;
        padding-left: .3rem;
        box-shadow: -.5rem 0 .5rem rgba(0, 0, 0, 0.1);
        // background-image: linear-gradient(to right, #00000000 0%, #ffffff 30%, #ffffff 100%);
    }

    &-left {
        left: 0;
        padding-right: .3rem;
        box-shadow: .5rem 0 .5rem rgba(0, 0, 0, 0.1);
        // background-image: linear-gradient(to right, #ffffff 0%, #ffffff 70%, #00000000 100%);
    }

    &-card {
        // border-bottom: 1px solid #E7E7E7;

        &:not(:last-child) {
            border-bottom: 1px solid $gray-300;

            @include media-breakpoint-down(xs) {
                border-bottom: none
                }

            // border-bottom: 1px solid #E7E7E7;
        }
    }

    &-nav {
        display: flex;
        align-items: center;

        &-item {
            &:link {
                color: $danger
            }
        }

        .nav {
            -ms-overflow-style: none;
                /* for Internet Explorer, Edge */
                scrollbar-width: none;
                /* for Firefox */
                overflow-x: scroll !important;
            &::-webkit-scrollbar {
                display: none;
                    /* for Chrome, Safari, and Opera */
            }
        }
    }

    &-item {


        &-desc {
            display: -webkit-box;
            overflow: hidden;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            white-space: normal;
        }
    }


}

.anchor {
    display: block;
    position: relative;
    visibility: hidden;
    top: 5rem;
}

.label-meal-plan {
    color: #2196F3;
    border: 1px solid #2196F3;
    background-color: transparent;
}

.pac-container {
    z-index: 1051 !important;
}

@include media-breakpoint-down(sm) {
    .homepage-preview {
        max-height: 700px !important;
        min-height: 450px !important;
        height: 100% !important;
    }
    .homepage-preview-store {
        min-height: 65vh !important;
        max-height: 80vh !important;
        height: 600px !important;
    }
    .homepage-preview-container {
        height: 100% !important;
        min-height: unset !important;
        max-height: unset !important;
    }
    .homepage-preview-panel {
        height: 100% !important;
        min-height: unset !important;
        max-height: 650px !important;
        padding: 25px !important;
    }
    .homepage-preview-sec {
        max-height: unset !important;
        min-height: unset !important;
        height: 100% !important;
        .text-left {
            text-align: unset !important;
        }
    }
    .homepage-preview-img {
        max-height: 100% !important;
        min-height: unset !important;
        position: absolute !important;
    }
}

.res-close-btn {
    background-color: #eef0f6 !important;
    border: none;
    .res-close-icon {
        font-size: 1rem;
        color: #484848;
    }
}

.restriction-warning-icon {
    --fa-primary-color: gold;
}

.checkout-sticky-message,
.checkout-sticky-message-external {
  text-align: center;
  position: fixed;
  opacity: 0.92;
  left: 50%;
  width: 100%;
  transform: translateX(-50%);
  z-index: 1000;
  transition: opacity 0.3s ease;
}

.checkout-sticky-message {
  top: 65px;
}

.checkout-sticky-message-external {
  top: 0;
}

@include media-breakpoint-down(md) {
    .checkout-sticky-message {
        top: 55px;
    }
}
  