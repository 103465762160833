@keyframes ldio-48qah01n87u-1 {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(-45deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
@keyframes ldio-48qah01n87u-2 {
  0% {
    transform: rotate(180deg);
  }
  50% {
    transform: rotate(225deg);
  }
  100% {
    transform: rotate(180deg);
  }
}
.ldio-48qah01n87u > div:nth-child(2) {
  transform: translate(-15px, 0);
}
.ldio-48qah01n87u > div:nth-child(2) div {
  position: absolute;
  top: 40px;
  left: 40px;
  width: 120px;
  height: 60px;
  border-radius: 120px 120px 0 0;
  background: #26c59c;
  animation: ldio-48qah01n87u-1 1s linear infinite;
  transform-origin: 60px 60px;
}
.ldio-48qah01n87u > div:nth-child(2) div:nth-child(2) {
  animation: ldio-48qah01n87u-2 1s linear infinite;
}
.ldio-48qah01n87u > div:nth-child(2) div:nth-child(3) {
  transform: rotate(-90deg);
  animation: none;
}
@keyframes ldio-48qah01n87u-3 {
  0% {
    transform: translate(190px, 0);
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  100% {
    transform: translate(70px, 0);
    opacity: 1;
  }
}
.ldio-48qah01n87u > div:nth-child(1) {
  display: block;
}
.ldio-48qah01n87u > div:nth-child(1) div {
  position: absolute;
  top: 92px;
  left: -8px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #ff28a6;
  animation: ldio-48qah01n87u-3 1s linear infinite;
}
.ldio-48qah01n87u > div:nth-child(1) div:nth-child(1) {
  animation-delay: -0.67s;
}
.ldio-48qah01n87u > div:nth-child(1) div:nth-child(2) {
  animation-delay: -0.33s;
}
.ldio-48qah01n87u > div:nth-child(1) div:nth-child(3) {
  animation-delay: 0s;
}
.loadingio-spinner-bean-eater-ba63ynqescj {
  width: 200px;
  height: 200px;
  display: inline-block;
  overflow: hidden;
  /* background: #ffffff; */
  /* opacity: 0; */
  background: rgba(255, 255, 255, 0);
}
.ldio-48qah01n87u {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-48qah01n87u div {
  box-sizing: content-box;
}
/* generated by https://loading.io/ */
