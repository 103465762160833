.brand-logo-div-image-container {
  padding-top: 3rem;
  padding-bottom: 3rem;
  text-align: center;
}

.brand-logo-div-image {
  max-width: 275px;
  max-height: 120px;
}

.text-gray {
  color: #464e5f;
}

.form-time-picker {
  height: calc(1.5em + 1.3rem + 2px);
  box-shadow: none;
  border: 1px solid #e5eaee;
  font-size: 1rem;
  font-weight: 400;
  color: #464e5f;
  padding: 0.65rem 1rem;
  border-radius: 0.42rem;
  line-height: 1.5rem;

  &:focus-visible {
    border-color: #23e9ba;
    outline: 0;
  }
}
